import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Popconfirm,
  Skeleton,
  Card,
  Form,
  List,
  Typography,
  Flex
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import { useListContentCaregiver } from './listContents/listContentCaregiver';
import { useListContentCenter } from './listContents/listContentCenter';
import { useListContentInvoice } from './listContents/listContentInvoice';
import { useListContentRac } from './listContents/listContentRac';
import { CommentsDrawer } from '../../components/CommentsDrawer/CommentsDrawer';
import { CommentsButton } from '../../components/CommentsButton/CommentsButton';
import { useDownloadDocument } from '../../utils/downloadDoc';

/**
 * Component for displaying details of a patient.
 * @returns {JSX.Element} - JSX for displaying patient details.
 */

export const ShowPatient = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patient, setPatients] = useState({});
  const listContent = useListContent(patient);
  const listContentCaregiver = useListContentCaregiver(patient);
  const listContentCenter = useListContentCenter(patient);
  const listContentInvoice = useListContentInvoice(patient);
  const listContentRac = useListContentRac(patient);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [form] = Form.useForm();
  const [formEditComment] = Form.useForm();
  const { downloadDocument } = useDownloadDocument();

  const getPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/patients/${id}?populate=comments.author,center,prescriptions,payingagency_amo,payingagency_amc,prescriber,initial_prescription,health_insurance`
      });

      if (data?.status === 'ACTIVE') setPatients(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const deletePatient = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/patients/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/patients/comments/${id}`,
        body
      });
      form.resetFields();
      getPatients();
    } catch (e) {
      message(e);
    }
  };

  const handleEditValidateClick = async (body, editingKey) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/patients/comments/${id}/${editingKey}`,
        body
      });
      formEditComment.resetFields();
      getPatients();
    } catch (e) {
      message(e);
    }
  };

  const deleteResource = async (editingKey) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/patients/comments/${id}/${editingKey}`
      });
      formEditComment.resetFields();
      getPatients();
    } catch (e) {
      message(e);
    }
  };

  const showDrawer = () => {
    setSize('large');
    setOpen(true);
  };

  const handleDownloadPrescription = () => {
    if (
      patient &&
      patient.initial_prescription &&
      patient.initial_prescription[0]
    ) {
      downloadDocument(patient.initial_prescription[0]);
    }
  };

  const handleDownloadHealthInssurance = () => {
    if (patient && patient.health_insurance && patient.health_insurance[0]) {
      downloadDocument(patient.health_insurance[0]);
    }
  };

  useEffect(() => {
    (async () => {
      await getPatients();
    })();
  }, [getPatients]);

  return (
    <>
      <ContentCustom>
        <PageHeaderCustom
          title={`${patient?.last_name} ${patient?.first_name}`}
          extra={
            <>
              {patient?.initial_prescription &&
                patient.initial_prescription[0] && (
                  <Button type="primary" onClick={handleDownloadPrescription}>
                    {`${t('buttons.download_prescription')} `}
                    <EyeOutlined />
                  </Button>
                )}
              {patient?.health_insurance && patient.health_insurance[0] && (
                <Button type="primary" onClick={handleDownloadHealthInssurance}>
                  {`${t('buttons.download_health_insurrance')} `}
                  <EyeOutlined />
                </Button>
              )}
              <CommentsButton
                count={patient?.comments?.length}
                showDrawer={showDrawer}
              />
              <Link to={{ pathname: `${routes.PATIENTS}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deletePatient}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          }
        />
        <Flex vertical gap={20}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card title={t('patients.form.titles.patient')}>
              <DescriptionList data={listContent} translate />
            </Card>
          </Skeleton>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card title={t('patients.caregiver')}>
              <DescriptionList data={listContentCaregiver} translate />
            </Card>
          </Skeleton>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card title={t('patients.center.center_name')}>
              <DescriptionList data={listContentCenter} translate />
            </Card>
            <Card>
              <List
                size="small"
                header={t('patients.reminders.prescriptions_title')}
                bordered
                dataSource={patient.reminders_prescription}
                renderItem={(item, index) => (
                  <List.Item>
                    [{index + 1}]{': '}
                    <Typography.Text>
                      {t('patients.form.reminder_prescription.type')} :
                    </Typography.Text>{' '}
                    {t(`enums.${item.type}`)} :
                    <Typography.Text>
                      {' '}
                      {t('patients.form.reminder_prescription.date')} :
                    </Typography.Text>{' '}
                    {moment(item.date).format('DD/MM/YYYY')}
                  </List.Item>
                )}
              />
            </Card>
          </Skeleton>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card title={t('patients.invoice')}>
              <DescriptionList data={listContentInvoice} translate />
            </Card>
            <Card>
              <List
                size="small"
                header={t('patients.reminders.invoices_title')}
                bordered
                dataSource={patient.reminders_insurance_card}
                renderItem={(item, index) => (
                  <List.Item>
                    [{index + 1}]{': '}
                    <Typography.Text>
                      {t('patients.form.reminder_prescription.type')} :
                    </Typography.Text>{' '}
                    {t(`enums.${item.type}`)} :
                    <Typography.Text>
                      {' '}
                      {t('patients.form.reminder_prescription.date')} :
                    </Typography.Text>{' '}
                    {moment(item.date).format('DD/MM/YYYY')}
                  </List.Item>
                )}
              />
            </Card>
          </Skeleton>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Card title={t('patients.rac')}>
              <DescriptionList data={listContentRac} translate />
            </Card>
            <Card>
              <List
                size="small"
                header={t('patients.reminders.rac_title')}
                bordered
                dataSource={patient.reminders_rac}
                renderItem={(item, index) => (
                  <List.Item>
                    [{index + 1}]{': '}
                    <Typography.Text>
                      {t('patients.form.reminder_prescription.type')} :
                    </Typography.Text>{' '}
                    {t(`enums.${item.type}`)} :
                    <Typography.Text>
                      {' '}
                      {t('patients.form.reminder_prescription.date')} :
                    </Typography.Text>{' '}
                    {moment(item.date).format('DD/MM/YYYY')}
                  </List.Item>
                )}
              />
            </Card>
          </Skeleton>
        </Flex>
      </ContentCustom>
      <CommentsDrawer
        title={t('patients.drawer.addComment.title')}
        onClose={onClose}
        open={open}
        size={size}
        updateResource={updateResource}
        deleteResource={deleteResource}
        handleEditValidateClick={handleEditValidateClick}
        form={form}
        formEditComment={formEditComment}
        models={patient}
      />
    </>
  );
};
